<template>
  <div class="page">
    <div class='head'>
      <div class="title">
        <p>起名技巧</p>
      </div>
    </div>
    <van-skeleton v-if="loading" :row="5" />
    <div v-else>
      <div v-html="how_str"></div>
      <button class="in_body black" data-value="index" @click="xml_go_to_only">试试看</button>
      <ul id="last_right_b" class="last_right">
        <li  @click="xml_go_to_only" data-value="index"  class="home"><img src="@/assets/img/home.png" /></li>
      </ul>
    </div>
    <van-popup v-model:show="show.zc" round position="bottom" :style="{ height: '72%' }" @close="on_close">
      <Login></Login>
    </van-popup>
  </div>
</template>

<script>
import store from '@/store'
import Utils from '@/utils/utils'
import axios from 'axios'

import Login from '@/components/other/Login'

export default {
  name: 'group_how',
  store,
  components: {
    Login
  },
  data () {
    return {
      show: { zc: false },
      loading: true,
      loading2: false,
      niming: store.state.niming,
      base_img: store.state.base_img,
      how_str: ''
    }
  },

  mounted () {
    this.get_how()
  },
  methods: {
    get_how () {
      this.loading = true
      axios.post(!this.niming ? '/get_how/' : '/get_how_unlogin/', {})
        .then(res => {
          this.loading = false
          this.how_str = res.data.data.how_str
        })
    },
    xml_go_to_goods_index (e) {
      if (!localStorage.getItem('token')) {
        this.show.zc = true
        return
      }
      Utils.go_to_goods_index(e)
    },
    xml_go_to_only (e) {
      Utils.go_to(e)
    },
    on_close () {
      this.show = { zc: false }
    },
    pull_up (e) {
      this.show[e.currentTarget.dataset.value] = true
    }
  }
}
</script>

<style>
.van-tab__pane{
  text-align: left;
}

.section{
  padding: 0.5rem 0.5rem;
  margin: 0.5rem 0rem;
  background-color: #fefefe;
  font-size: 0.4rem;
  line-height: 0.6rem;
}

.section .title {
  text-align: center;
  color: #fefefe;
  width: 8rem;
  margin: 0.5rem auto;
  background:rgba(37, 130, 173, 0.3);
  font-size: 0.5rem;
  height: 0.8rem;
  line-height: 0.8rem;
  border-radius: 0.8rem;
}

.section .one_desc,.section .we_do{
  text-align: left;
  margin: 0.2rem 0rem;
}
.section .one_desc .p,.section .we_do .p{
  text-align: left;
  margin: 0.25rem 0rem;
}
.section .we_do {
  background: rgba(100, 184, 214,0.05);
  padding:0.2rem 0.2rem;
  border-radius: 0.2rem;
}
.section .we_do .p{
  color:rgba(37, 130, 173, 0.5);
}

.section .img{
  display: block;
  width: 8rem;
  margin: 0.4rem auto;
  box-shadow:0.02rem 0.02rem 0.1rem #888;
}
</style>
